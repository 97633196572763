/* React Color Pallate  */
.rcp {
  box-shadow: 0px 12px 23px #c2ccde8a;
}
.rcp-saturation {
  border-radius: 6px 6px 0 0;
}
.rcp-body {
  padding: 15px;
  border: 1px solid #bfcfe0;
  border-radius: 0 0 6px 6px;
}
.rcp-fields {
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
}

.rcp-fields-element-input {
  font-size: 13px;
  border-radius: 6px;
  border: 1px solid #bfcfe0;
}
.rcp-fields-element-label {
  font-size: 12px;
}
