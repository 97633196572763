@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #202223;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: grid;
}

.sidebar {
  display: grid;
  padding: 20px 10px;
  background-color: wheat;
  min-height: calc(100vh - 40px);
}
