/* React-dates */
.DateRangePicker {
    height: 38px;
    max-width: 100%;
    width: fit-content;
  }
  .DateRangePickerInput {
    height: 38px;
    display: flex;
    border-radius: 4px;
  }
  .DateInput {
    height: 34px;
    width: 100px;
    margin-left: 5px;
    margin-top: 1px;
  }
  .DateInput_input {
    height: 36px;
    font-size: 14px;
    color: #808080;
  }
  .CalendarMonth_caption {
    color: #3A6B60;
  }
  .DayPicker_weekHeader {
    color: #3A6B60;
  }
  .DateRangePickerInput_calendarIcon {
    margin: 0 5px;
  }
  .date-range-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
  }
  .date-range-picker-button {
    background: white;
    border: 1px solid #3A6B60;
    color: #3A6B60;
    height: 38px;
    padding: 2px 10px;
 }
  .date-range-picker-button:hover {
    background-color: #3A6B60 !important;
    color: white !important;
    border: 2px solid #3A6B60 !important;
 }
  .date-range-picker-button:focus {
    background-color: #3A6B60 !important;
 }
  
  /* -----Single Date Picker */
  .SingleDatePicker {
    width: 100%;
    height: 38px;
    border: 1px solid #e4e7eb;
    border-radius: 4px;
  }
  .SingleDatePickerInput {
    width: 100%;
    height: 36px;
    display: flex;
    border: none;
    border-radius: 4px;
 }
  .SingleDatePickerInput .DateInput {
    height: 36px;
    margin: 0;
    width: 100% !important;
 }
  .SingleDatePickerInput .DateInput_input {
    width: fit-content;
    font-size: 13px;
    color: #2f394e;
 }
  .SingleDatePickerInput .DateInput_input::placeholder {
    color: rgba(47, 57, 78, 0.7);
 }
  .SingleDatePickerInput .DateInput_input__focused {
    border-bottom: 2px solid #c2cce1;
 }
  .SingleDatePickerInput_arrow {
    margin: 10px 7px;
    font-size: 16px;
  }
  
  /* -----End React-dates */